import { createRouter, createWebHistory } from "vue-router";
import NProgress from "nprogress";

const routes = [
  {
    path: "/home",
    name: "root",
    component: () => import("../views/Home"),
  },
  {
    path: "/",
    name: "home",
    component: () => import("../views/Home"),
  },

  {
    path: "/roadmap",
    name: "roadmap",
    component: () => import("../components/RoadMap"),
  },
  {
    path: "/blog",
    name: "blog",
    component: () => import("../views/Blog"),
  },

  {
    path: "/support-us",
    name: "support-us",
    component: () => import("../views/SupportUs"),
  },

  {
    path: "/ecosystem-partners",
    name: "partners",
    component: () => import("../components/OurPartners"),
  },
  {
    path: "/team-not-ready",
    name: "team",
    component: () => import("../views/Team"),
  },
  {
    path: "/archive",
    name: "archive",
    component: () => import("../views/Archive"),
  },
  {
    path: "/about",
    name: "about",
    component: () => import("../views/About"),
  },
  {
    path: "/private-sale-not-available",
    name: "private-sale",
    component: () => import("../views/PrivateSale"),
  },
  {
    path: "/launching-soon",
    name: "launching-soon",
    component: () => import("../views/LaunchingSoon"),
  },
  {
    path: "/projects",
    name: "projects",
    component: () => import("../views/Projects"),
  },
  {
    path: "/jobs",
    name: "jobs",
    component: () => import("../views/MarketplaceJobs"),
  },
  {
    path: "/job-details",
    name: "JobDetails",
    component: () => import("../views/JobDeatils"),
  },
  {
    path: "/affiliate",
    name: "affiliate",
    component: () => import("../views/Affiliates"),
  },
  {
    path: "/request-for-proposal",
    name: "rfc",
    component: () => import("../views/RequestForProposal"),
  },
  {
    path: "/faq",
    name: "faq",
    component: () => import("../views/Faq"),
  },
  {
    path: "/disclaimer",
    name: "disclaimer",
    component: () => import("../views/Disclaimer"),
  },
  {
    path: "/error",
    name: "Error",
    component: () => import("../views/Error"),
  },
  {
    path: "/terms-of-service",
    name: "Terms",
    component: () => import("../views/Terms"),
  },
  {
    path: "/privacy-policy",
    name: "PrivacyPolicy",
    component: () => import("../views/PrivacyPolicy"),
  },
  {
    path: "/contact-us",
    name: "contact-us",
    component: () => import("../components/Contact"),
  },
  {
    path: "/our-partners",
    name: "our-partners",
    component: () => import("../views/OurPartners"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((To, From, next) => {
  console.log(From);
  NProgress.start();
  next();
});

router.afterEach(() => {
  NProgress.done();
});

export default router;
