<template>
  <!-- Footer -->
  <MDBFooter :text="['center', 'lg-start']">
    <div style="background-color: #d50000; color: white">
      <!-- Grid container -->
      <MDBContainer class="p-4">
        <!--Grid row-->
        <MDBRow>
          <!--Grid column-->
          <MDBCol lg="4" md="4" class="mb-4 mb-md-0">
            <img
              src="@/assets/logo.png"
              class="d-block"
              alt="Logo"
              style="filter: grayscale(100%); width: 100px"
            />
            <h5 class="text-uppercase">TAMIL Crypto</h5>

            <p>
              Elevating the world's oldest living classical language through
              blockchain technology.
            </p>
            <p>The TAMIL Crypto operates on a community-driven model.</p>
          </MDBCol>
          <!--Grid column-->

          <!--Grid column-->
          <MDBCol lg="2" md="2" class="mb-4 mb-md-0"> </MDBCol>
          <!--Grid column-->

          <!--Grid column-->
          <MDBCol lg="2" md="2" class="mb-4 mb-md-0">
            <h5 class="text-uppercase mb-0">Social</h5>

            <ul class="list-unstyled">
              <li>
                <hr />
              </li>

              <li>
                <a
                  href="https://www.facebook.com/tamiltoken.org"
                  target="_blank"
                >
                  <i class="fab fa-facebook-f"></i> Facebook
                </a>
              </li>
              <li>
                <a href="https://twitter.com/tamiltoken" target="_blank">
                  <i class="fab fa-twitter"></i> Twitter
                </a>
              </li>
              <li>
                <a href="https://instagram.com/tamiltoken" target="_blank">
                  <i class="fab fa-instagram"></i> Instagram
                </a>
              </li>
              <li>
                <a href="https://discord.gg/FAZnUZgjt4" target="_blank">
                  <i class="fab fa-discord"></i> Discord
                </a>
              </li>

              <li>
                <a href="https://www.reddit.com/r/tamilcrypto/" target="_blank">
                  <i class="fab fa-reddit-alien"></i> Reddit
                </a>
              </li>

              <li>
                <a href="https://t.me/tamiltoken" target="_blank">
                  <i class="fab fa-telegram-plane"></i> Telegram
                </a>
              </li>

              <li>
                <a
                  href="https://www.youtube.com/channel/UC-oEEeaPFhZLIzKtQskgx7g"
                  target="_blank"
                >
                  <i class="fab fa-youtube"></i> Youtube
                </a>
              </li>

              <li>
                <a href="https://tamiltoken.medium.com/" target="_blank">
                  <i class="fab fa-medium-m"></i> Blog
                </a>
              </li>
            </ul>
          </MDBCol>
          <!--Grid column-->

          <!--Grid column-->
          <MDBCol lg="2" md="2" class="mb-4 mb-md-0">
            <h5 class="text-uppercase mb-0">Resources</h5>

            <ul class="list-unstyled mt-2">
              <li>
                <hr />
              </li>

              <li>
                <a href="/request-for-proposal">Request for Proposal</a>
              </li>

              <li>
                <hr />
              </li>

              <li>
                <a href="/roadmap">Development Roadmap</a>
              </li>

              <li>
                <hr />
              </li>

              <li>
                <a
                  href="https://coinmarketcap.com/community/profile/TamilCrypto/"
                  target="_blank"
                >
                  <img
                    src="../assets/images/CoinMarketCap.png"
                    alt="CoinMarketCap"
                    class="CoinMarketCap"
                  />
                </a>
              </li>

              <li>
                <hr>
              </li>

              <li>
                <a href="/faq">FAQ </a> <br />
              </li>

   
              

            </ul>
          </MDBCol>

          <!--Grid column-->
          <MDBCol lg="2" md="2" class="mb-4 mb-md-0">
            <h5 class="text-uppercase mb-0">Contact</h5>

            <ul class="list-unstyled">
              <li>
                <hr />
              </li>

              <li>
                <a href="/affiliate">Affiliate </a>
              </li>

              <li>
                <hr />
              </li>

              <li>
                <a href="https://reddit.com/r/tamilcrypto">Reddit Community </a>
              </li>

              <li>
                <hr/>
              </li>

              <li>
                <a href="/support-us">Support Us </a> <br />
              </li>

            </ul>
          </MDBCol>

          <!--Grid column-->
        </MDBRow>
        <!--Grid row-->
      </MDBContainer>
      <!-- Grid container -->
    </div>

    <!-- Copyright -->
    <div class="text-center p-3" style="background-color: rgba(0, 0, 0, 0.3)">
      <strong>
        COPYRIGHT &copy; 2024 TAMIL Crypto | All Rights Reserved
      </strong>
    </div>
    <!-- Copyright -->

    <!-- Social Media -->
    <div class="text-center p-3" style="background-color: rgba(0, 0, 0, 0.4)">
      <router-link to="/terms-of-service" class="no-effect padd"
        >Term Of Service</router-link
      >
      <router-link to="/privacy-policy" class="no-effect padd"
        >Privacy Policy</router-link
      >
      <router-link to="/disclaimer" class="no-effect padd"
        >Disclaimer</router-link
      >
    </div>
    <!-- Social Media -->
  </MDBFooter>
  <!-- Footer -->
</template>

<script>
import { MDBFooter, MDBContainer, MDBRow, MDBCol } from "mdb-vue-ui-kit";

export default {
  components: {
    MDBFooter,
    MDBContainer,
    MDBRow,
    MDBCol,
  },
};
</script>

<style scoped>
.no-effect {
  text-decoration: none;
  color: #2c3e50;
}
.padd {
  padding-left: 20px;
}

a {
  color: rgb(255, 255, 255);
}
.fab {
  width: 15px;
  padding-right: 2px;
}

.CoinMarketCap {
  width: 140px;
  height: auto;
}
</style>
