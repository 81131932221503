<template>
  <MDBNavbar expand="lg" light bg="light" position="sticky" container>
    <!-- .d-block .d-sm-none visible only on xs -->
    <MDBNavbarBrand class="d-block d-sm-none" href="/">
      <img src="@/assets/logo.png" height="50" alt="" loading="lazy" />
    </MDBNavbarBrand>

    <MDBNavbarToggler
      @click="toggledNav = !toggledNav"
      target="#navbarSupportedContent"
    ></MDBNavbarToggler>

    <MDBCollapse v-model="toggledNav" id="navbarSupportedContent">
      <MDBNavbarNav class="mb-2 mb-lg-0">
        <MDBNavbarItem href="#">
          <router-link
            to="/"
            class="nav-link waves-effect waves-light"
            :class="activeClass('home')"
          >
            <i class="fa fa-home"></i> Home
            <span class="sr-only">(current)</span>
          </router-link>
        </MDBNavbarItem>

        <MDBNavbarItem href="#">
          <router-link
            to="/about"
            class="nav-link waves-effect waves-light"
            :class="activeClass('about')"
          >
            About
          </router-link>
        </MDBNavbarItem>

        <MDBNavbarItem href="#">
          <router-link
            to="/projects"
            class="nav-link waves-effect waves-light"
            :class="activeClass('projects')"
          >
            Projects
          </router-link>
        </MDBNavbarItem>

        <MDBNavbarItem href="#">
          <router-link
            to="/archive"
            class="nav-link waves-effect waves-light"
            :class="activeClass('archive')"
          >
            Archives
          </router-link>
        </MDBNavbarItem>

        <MDBNavbarItem href="#">
          <router-link
            to="/our-partners"
            class="nav-link waves-effect waves-light"
            :class="activeClass('our-partners')"
          >
            Our Partners
          </router-link>
        </MDBNavbarItem>
      </MDBNavbarNav>

      <MDBNavbarBrand class="d-none d-md-block" href="/">
        <img src="@/assets/logo.png" height="60" alt="" loading="lazy" />
      </MDBNavbarBrand>

      <MDBNavbarNav right class="mb-2 mb-lg-0">
        <MDBNavbarItem href="#">
          <router-link
            to="/jobs"
            class="nav-link"
            :class="activeClass('jobs', 'JobDetails')"
          >
            Jobs
          </router-link>
        </MDBNavbarItem>

        <MDBNavbarItem href="#">
          <router-link to="/faq" class="nav-link" :class="activeClass('faq')">
            FAQ
          </router-link>
        </MDBNavbarItem>

        <MDBNavbarItem href="#">
          <a
            class="nav-link waves-effect waves-light"
            href="https://tamiltoken.medium.com/"
            target="_blank"
          >
            Blog
          </a>
        </MDBNavbarItem>

        <MDBNavbarItem href="#">
          <router-link
            to="/contact-us"
            class="nav-link"
            :class="activeClass('contact-us')"
          >
            Contact Us
          </router-link>
        </MDBNavbarItem>

        <MDBNavbarItem href="#">
          <a
            class="btn btn-warning"
            href="https://app.tamiltoken.org"
            target="_blank"
          >
            <i class="fas fa-sign-in-alt"></i> Log In
          </a>
        </MDBNavbarItem>
      </MDBNavbarNav>
    </MDBCollapse>
  </MDBNavbar>

  <!-- Modal -->
  <div
    class="modal fade"
    id="startUpModal"
    tabindex="-1"
    aria-labelledby="startUpModalLable"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered modal-lg">
      <div class="modal-content">
        <div class="modal-body">
          <button
            type="button"
            class="btn-close mb-3"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
          <img
            src="@/assets/images/modal-header.png"
            alt="Modal Header Img"
            class="modal-header-img w-100"
          />
          <div class="modal-cards mt-3">
            <div class="row">
              <div class="col-md-4 col-lg-4 col-sm-12">
                <div class="card">
                  <div class="card-body text-center">
                    <h3>
                      <i class="fas fa-desktop"></i>
                    </h3>
                    <h1>
                      <span class="count"> 1000 </span>
                    </h1>
                    <strong>Node Operators</strong>
                  </div>
                </div>
              </div>
              <div class="col-md-4 col-lg-4 col-sm-12">
                <div class="card">
                  <div class="card-body text-center">
                    <h3>
                      <i class="fas fa-file-alt"></i>
                    </h3>
                    <h1>
                      <span class="count"> 1000 </span>
                    </h1>
                    <strong>Decentralized Tamil Digital Assets</strong>
                  </div>
                </div>
              </div>
              <div class="col-md-4 col-lg-4 col-sm-12">
                <div class="card">
                  <div class="card-body text-center">
                    <h3>
                      <i class="fas fa-gifts"></i>
                    </h3>
                    <h1>
                      <span class="count"> 1000 </span>
                    </h1>
                    <strong>Rewards Paid</strong>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Modal -->
</template>

<script>
import {
  MDBNavbar,
  MDBNavbarToggler,
  MDBNavbarBrand,
  MDBNavbarNav,
  MDBNavbarItem,
  MDBCollapse,
} from "mdb-vue-ui-kit";
export default {
  data() {
    return {
      signedIn: false,
      toggledNav: false,
    };
  },
  components: {
    MDBNavbar,
    MDBNavbarToggler,
    MDBNavbarBrand,
    MDBNavbarNav,
    MDBNavbarItem,
    MDBCollapse,
  },
  watch: {
    $route() {
      this.toggledNav = false;
    },
  },
  methods: {
    activeClass: function(...names) {
      for (let name of names) {
        if (name == this.$route.name) return "active";
      }
    },
  },
};
</script>

<style scoped>
#navbarSupportedContent {
  height: 60px;
}

.vg-body {
  display: grid;
  grid-template-columns: auto auto auto;
  padding: 10px;
  background: lightgrey;
}

.vg-container {
  padding: 20px;
  font-size: 30px;
  text-align: center;
}
.vg-text {
  color: black;
  padding-left: 10px;
}

.vg-items {
  display: flex;
  align-items: center;
}

.vg-flag {
  height: 40px;
}

.card {
  height: 100%;
}

.btn-close {
  background-color: rgb(190, 190, 190);
  border-radius: 50%;
  padding: 10px;
}

.nav-link.active {
  color: rgb(91 90 90) !important;
  font-weight: bolder !important;
}
</style>
