<template>
	<div class="homeStaticImage">
		<div class="overlay">
			<h1>தமிழ் 𑀢𑀫𑀺𑀵𑁆 - TAMIL Crypto </h1>
			<h2>The first cryptocurrency token created specifically for and dedicated to the Tamil language.</h2>
			<br><br><br><br><br><br><br>
			<h3>Elevating the world's oldest living classical language through blockchain technology</h3>
			
			<!-- <MDBBtn tag="a" size="lg" class="active"  aria-pressed="true" color="primary" href="/dashboard" style="position: absolute;bottom: 50px;left: 0;background-color: rgb(198, 17, 24);"><i class="fa fa-user"></i> Get Involved</MDBBtn> -->

		</div>

		

	</div>
</template>

<style scoped>
.homeStaticImage {
    background: url('../assets/images/tmailtoken-wall-paper.png') no-repeat fixed center;
	background-size: cover;
	background-attachment: fixed;

	text-align: center;
	width: 100%;
	position: relative;
	overflow: hidden;
	border-radius: 0 0 85% 85% / 30%;
}
.homeStaticImage .overlay{
	width: 100%;
	height: 100%;
	padding: 50px;
	color: #FFF;
	text-shadow: 1px 1px 1px #333;

	
}

@media only screen and (max-width: 600px) {
  .homeStaticImage {
    
    height: 350px;
  }
}

@media only screen and (min-width: 900px) {
   .homeStaticImage {
    height: 600px;

  }
}




</style>

<script>
  //import { MDBBtn } from "mdb-vue-ui-kit";

  export default {
    components: {
      
  
    }
  };
</script>