
<template>
  <header>
    <NavBar />
  </header>
  <HomeStaticImage v-show="homeView" />
  <MDBContainer style="width: 100%">
    <div id="google_translate_element" class="mt-2" />
    <router-view />
  </MDBContainer>
  <Footer />
</template>

<script>
import { MDBContainer } from "mdb-vue-ui-kit";

import Footer from "@/components/Footer";
import NavBar from "@/components/NavBar";
//import LoadingOverlay from "@/components/LoadingOverlay";

import HomeStaticImage from "@/components/HomeStaticImage";

export default {
  data() {
    return {
      homeView: true,
    };
  },
  components: {
    MDBContainer,
    NavBar,
    Footer,
    HomeStaticImage,
  },
  watch: {
    $route() {
      console.log(this.$route.name);
      if (this.$route.name == "home") {
        // check router and show home banner
        this.homeView = true;
      } else {
        this.homeView = false;
      }
    },
  },
};
</script>



<style>
#app {
  font-family: Roboto, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
router-view {
  width: 100%;
}
</style>
